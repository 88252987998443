/**
 * 如果需要按钮权限控制,需要在相对应的路由的meta中加上关键字,并默认true,后台权限需要加上是否为false
 * 相应的button中要加上v-permission='字段'
 */
const dynamicRoutes = [
    // 客户
    {
        path: "/cus",
        redirect: "/cus/index",
        name: "CusManage",
        type: "cus",
        meta: {
            name: "客户管理",
            icon: "Reading",
        },
        children: [
            {
                path: "/cus/index",
                name: "CusIndex",
                type: "cus",
                show: true,
                meta: {
                    name: "客户首页",
                    icon: "User",
                    keepAlive: true,
                },
                component: () => import("@/View/Customer/index.vue"),
            },
            {
                path: "/cus/newCus",
                name: "CusNewCus",
                type: "cus",
                show: true,
                meta: {
                    name: "新建客户",
                    icon: "Plus",
                    keepAlive: false,
                },
                component: () =>
                    import("@/View/Customer/newCustomer/index.vue"),
            },
            {
                name: "CusDetails",
                type: "cus",
                show: false,
                meta: {
                    name: "客户详情",
                    icon: "Rank",
                    keepAlive: true,
                },
                component: () => import("@/View/Customer/details/index.vue"),
                path: "/cus/details",
                props(route) {
                    return {
                        query: route.query.id,
                    };
                },
            },
        ],
    },
    // 案件
    {
        path: "/case",
        name: "CaseManage",
        type: "case",
        // redirect: '/case/index',
        meta: {
            name: "案件管理",
            icon: "Files",
        },
        children: [
            {
                path: "/case/index",
                name: "CaseIndex",
                type: "case",
                show: true,
                meta: {
                    name: "案件首页",
                    icon: "Memo",
                    del: true,
                    keepAlive: true,
                },
                component: () => import("@/View/Case/Index.vue"),
            },
            {
                path: "/case/newCase",
                name: "CaseNewCase",
                type: "case",
                show: true,
                meta: {
                    name: "新建案件",
                    icon: "DocumentAdd",
                    keepAlive: false,
                },
                component: () => import("@/View/Case/NewCase/Index.vue"),
            },
            {
                name: "CaseDetails",
                path: "/case/details",
                type: "case",
                show: false,
                meta: {
                    name: "案件详情",
                    icon: "Rank",
                    keepAlive: true,
                },
                component: () => import("@/View/Case/CaseDetails/Index.vue"),
                props(route) {
                    return {
                        query: route.query.id,
                    };
                },
            },
            {
                path: "/case/browse",
                name: "CaseBrowse",
                type: "browseCase",
                show: false,
                meta: {
                    name: "案件浏览",
                    icon: "Rank",
                    keepAlive: true,
                },
                component: () => import("@/View/Case/browse/index.vue"),
            },
        ],
    },
    // 产品
    {
        path: "/product",
        // redirect: '/product/index',
        name: "Product",
        type: "product",
        meta: {
            name: "产品管理",
            icon: "Shop",
        },
        children: [
            {
                path: "/product/index",
                name: "ProductIndex",
                type: "product",
                show: true,
                meta: {
                    name: "产品首页",
                    icon: "ElemeFilled",
                    add: true,
                    edit: true,
                    details: true,
                    del: true,
                    keepAlive: true,
                },
                component: () => import("@/View/Product/index.vue"),
            },
            {
                path: "/product/newProduct",
                name: "NewProduct",
                type: "product",
                show: true,
                meta: {
                    name: "新建产品",
                    icon: "Plus",
                    keepAlive: false,
                },
                component: () => import("@/View/Product/newProduct/index.vue"),
            },
            {
                path: "/product/details",
                name: "ProDetails",
                type: "product",
                show: false,
                meta: {
                    name: "产品详情",
                    icon: "Rank",
                    keepAlive: true,
                },
                component: () => import("@/View/Product/details/index.vue"),
            },
            {
                path: "/product/browse",
                name: "ProBrowse",
                type: "browseProduct",
                show: false,
                meta: {
                    name: "产品浏览",
                    icon: "Rank",
                    keepAlive: true,
                },
                component: () => import("@/View/Product/browse/index.vue"),
            },
            {
                path: "/product/marketPro",
                name: "MarketPro",
                type: "marketPro",
                show: true,
                meta: {
                    name: "市场产品",
                    icon: "Rank",
                    keepAlive: true,
                },
                component: () => import("@/View/Product/marketPro/index.vue"),
            },
        ],
    },
    // 渠道
    {
        path: "/channel",
        // redirect: '/channel/index',
        type: "channel",
        name: "ChannelManage",
        meta: {
            name: "渠道管理",
            icon: "SetUp",
        },
        children: [
            {
                path: "/channel/index",
                name: "ChannelIndex",
                type: "channel",
                show: true,
                meta: {
                    name: "渠道首页",
                    icon: "ScaleToOriginal",
                    keepAlive: true,
                },
                component: () => import("@/View/Channel/index.vue"),
            },
            {
                path: "/channel/newChannel",
                name: "NewChannel",
                type: "channel",
                show: true,
                meta: {
                    name: "新建渠道",
                    icon: "Plus",
                    keepAlive: false,
                },
                component: () => import("@/View/Channel/newChannel/index.vue"),
            },
            {
                path: "/channel/details",
                name: "ChannelDetails",
                type: "channel",
                show: false,
                meta: {
                    name: "渠道详情",
                    icon: "Rank",
                    keepAlive: true,
                },
                component: () => import("@/View/Channel/details/index.vue"),
            },
            {
                path: "/channel/funders",
                name: "Funders",
                type: "channel",
                show: true,
                meta: {
                    name: "资金方",
                    icon: "Rank",
                    keepAlive: true,
                },
                component: () => import("@/View/Channel/funders/index.vue"),
            },
        ],
    },
    // 组织
    {
        path: "/org",
        name: "Org",
        type: "org",
        meta: {
            name: "组织管理",
            icon: "Notification",
        },
        children: [
            {
                path: "/org/MyOrgs",
                name: "MyOrgs",
                type: "org",
                show: true,
                meta: {
                    name: "我的组织",
                    icon: "Pointer",
                    keepAlive: true,
                },
                component: () => import("@/View/Org/MyOrgs/index.vue"),
            },
            {
                path: "/org/MyJoinOrgs",
                name: "MyJoinOrgs",
                type: "org",
                show: true,
                meta: {
                    name: "我的加入",
                    icon: "Connection",
                    keepAlive: true,
                },
                component: () => import("@/View/Org/MyJoinOrgs/index.vue"),
            },
            {
                path: "/org/NewOrg",
                name: "NewOrg",
                type: "org",
                show: true,
                meta: {
                    name: "新建组织",
                    icon: "Plus",
                    keepAlive: true,
                },
                component: () => import("@/View/Org/newOrg/index.vue"),
            },
        ],
    },
    // 用户
    {
        path: "/user",
        // redirect: '/user/index',
        name: "UserManage",
        meta: {
            name: "账户管理",
            icon: "User",
        },
        children: [
            {
                path: "/user/register",
                name: "Register",
                show: true,
                meta: {
                    name: "用户注册",
                    icon: "Plus",
                    keepAlive: false,
                },
                component: () => import("@/View/User/Register"),
            },
            {
                path: "/user/index",
                name: "UserIndex",
                show: true,
                meta: {
                    name: "账户管理",
                    icon: "Avatar",
                    keepAlive: true,
                },
                component: () => import("@/View/User/UserIndex"),
            },
        ],
    },
    // 工具
    {
        path: "/tools",
        // redirect: 'creditRecognition',
        name: "ToolsManage",
        type: "tool",
        meta: {
            name: "工具箱",
            icon: "Cpu",
        },
        children: [
            {
                path: "/tools/creditRecognition",
                name: "CreditRecognition",
                type: "tool",
                show: true,
                meta: {
                    name: "个人征信",
                    icon: "DataLine",
                    keepAlive: true,
                },
                component: () =>
                    import("@/tools/CreditRecognition/CreditRecognition"),
            },
            {
                path: "/tools/creidtCompanyAuto",
                name: "CreidtCompanyAuto",
                type: "tool",
                show: true,
                meta: {
                    name: "企业征信",
                    icon: "Management",
                    keepAlive: true,
                },
                component: () => import("@/tools/CreditCompanyAuto/index.vue"),
            },
            {
                path: "/tools/getManageInfo",
                name: "GetManageInfo",
                type: "tool",
                show: true,
                meta: {
                    name: "经营数据",
                    icon: "Notification",
                    keepAlive: true,
                },
                component: () => import("@/tools/GetManageInfo/index.vue"),
            },
        ],
    },
    // 设置
    {
        path: "/set",
        name: "Setup",
        type: "set",
        meta: {
            name: "设置",
            icon: "Setting",
        },
        children: [
            {
                path: "/set/user",
                name: "SetUser",
                type: "set",
                show: true,
                meta: {
                    name: "用户字段",
                    icon: "DataLine",
                    keepAlive: true,
                },
                component: () => import("@/View/Set/userSet/index.vue"),
            },
            {
                path: "/set/manage",
                name: "SetManage",
                type: "set",
                show: true,
                meta: {
                    name: "系统字段",
                    icon: "Notification",
                    keepAlive: true,
                },
                component: () => import("@/View/Set/manageSet/index.vue"),
            },
        ],
    },
    // 管理
    {
        path: "/manage",
        name: "Manage",
        type: "manage",
        meta: {
            name: "管理",
            icon: "Operation",
        },
        children: [
            {
                path: "/manage/permission",
                name: "Permission",
                type: "manage",
                show: true,
                meta: {
                    name: "权限管理",
                    icon: "ElementPlus",
                    keepAlive: true,
                },
                component: () => import("@/View/Manage/permission/index.vue"),
            },
            {
                path: "/manage/productControl",
                name: "ProductControl",
                type: "manage",
                show: true,
                meta: {
                    name: "产品控制",
                    icon: "Magnet",
                    keepAlive: true,
                },
                component: () =>
                    import("@/View/Manage/ProductControl/index.vue"),
            },
            {
                path: "/manage/spideManage",
                name: "SpideManage",
                type: "manage",
                show: true,
                meta: {
                    name: "爬虫管理",
                    icon: "Connection",
                    keepAlive: true,
                },
                component: () => import("@/View/Manage/spideManage/index.vue"),
            },
        ],
    },
];

export default dynamicRoutes;
