// directives/mouseScroll.js, 鼠标滚轮滚动指令,在需要的组件上加v-mouse-scroll即可
export default {
    mounted(el) {
        let startY = 0;
        let isDragging = false;

        // 这个是鼠标左键点击滚动的事件,需要ctrl键,可以根据需要调整
        const onMouseDown = (event) => {
            if (event.ctrlKey && event.which === 1) {
                startY = event.clientY;
                isDragging = true;
                // 阻止默认的选中行为
                event.preventDefault();
            }
        };
        // 这个是鼠标左键点击滚动的事件,不需要ctrl键
        // const onMouseDown = (event) => {
        //     startY = event.clientY;
        //     isDragging = true;
        //     // 阻止默认的选中行为
        //     event.preventDefault();
        // };

        const onMouseMove = (event) => {
            if (!isDragging) return;

            const deltaY = event.clientY - startY;
            const scrollAmount = deltaY * 1.0; // 调整这个值来改变滚动的灵敏度

            // 使用 window.scrollBy 或 element.scrollTop 来模拟滚动
            el.scrollTop = el.scrollTop - scrollAmount;

            // 防止内容超出边界
            if (el.scrollTop < 0) {
                el.scrollTop = 0;
            } else if (el.scrollTop + el.clientHeight >= el.scrollHeight) {
                el.scrollTop = el.scrollHeight - el.clientHeight;
            }

            startY = event.clientY; // 更新起始位置，用于下一次计算
        };

        const onMouseUp = () => {
            isDragging = false;
        };

        // 绑定事件监听器
        el.addEventListener("mousedown", onMouseDown);
        document.addEventListener("mousemove", onMouseMove);
        document.addEventListener("mouseup", onMouseUp);
        document.addEventListener("mouseleave", onMouseUp);

        // 存储清理函数以便在指令解绑时调用
        el._cleanupMouseScroll = () => {
            el.removeEventListener("mousedown", onMouseDown);
            document.removeEventListener("mousemove", onMouseMove);
            document.removeEventListener("mouseup", onMouseUp);
            document.removeEventListener("mouseleave", onMouseUp);
        };
    },
    unmounted(el) {
        // 调用清理函数移除事件监听器
        if (el._cleanupMouseScroll) {
            el._cleanupMouseScroll();
        }
    },
};
